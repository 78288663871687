<template>
  <SplitPage v-if="authorization">
    <template #header>
      <AuthorizationBreadcrumb :active-step="2"/>
    </template>

    <template #left-top>
      <StepsTabs/>
    </template>

    <template #left-content>
      <template v-if="lastStepAdded">
        <div v-for="(step, index) in steps"
             :key="index">
          <template v-if="index === 0">
            <StepBlock title="Ajustez le point sur la carte"
                       :authorization="authorization"
                       :step="step"
                       :map="map"/>
            <StepDetails :step="step"/>
            <button @click="save(step)"
                    class="btn btn-block btn-secondary">
              Valider
            </button>
          </template>
          <template v-else-if="index === steps.length - 1">
          </template>
        </div>
      </template>
      <template v-else>
        <h1 class="text-center mb-4">Tracez votre<br> itinéraire</h1>
        <p class="text-center mb-5">en saisissant une adresse ou en plaçant un point directement sur la carte</p>
        <LocationAutocomplete @input="addStepToStore"
                              :way="0"
                              :authorization="authorization"/>
        <StepCreateButton @input="addStepToStore"
                          :way="0"
                          :authorization="authorization"
                          :map-center="mapCenter"/>
      </template>
    </template>

    <template #right-content>
      <AuthorizationMap @map-ready="onMapReady"
                        @map-update-center="onMapUpdateCenter"
                        @map-update-bounds="onMapUpdateBounds"
                        :center="center"/>
    </template>
  </SplitPage>
</template>

<script>
import SplitPage from '@/components/SplitPage'
import StepsTabs from '@/components/StepsTabs'
import AuthorizationBreadcrumb from '@/components/AuthorizationBreadcrumb'
import LocationAutocomplete from '@/components/LocationAutocomplete'
import StepCreateButton from '@/components/StepCreateButton'
import AuthorizationMap from '@/components/AuthorizationMap'
import StepBlock from '@/components/StepBlock'
import StepDetails from '@/components/StepDetails'
import { mapState, mapActions } from 'vuex'
import { findOneCity } from '@/api/premat/city'

export default {
  name: 'AuthorizationsStepsCreatePage',
  components: {
    SplitPage,
    StepsTabs,
    AuthorizationBreadcrumb,
    LocationAutocomplete,
    StepCreateButton,
    AuthorizationMap,
    StepBlock,
    StepDetails
  },
  data () {
    return {
      map: null,
      centerValue: null,
      mapCenter: null,
      mapBounds: null
    }
  },
  watch: {
    'authorization.city_start' () {
      this.getCenter()
    }
  },
  computed: {
    ...mapState({
      authorization: state => state.authorization.authorization,
      steps: state => state.authorization.steps,
      lastStepAdded: state => state.authorization.lastStepAdded
    }),
    center () {
      return this.centerValue
    }
  },
  methods: {
    ...mapActions({
      getAuthorization: 'authorization/getAuthorization',
      getSteps: 'authorization/getSteps',
      addStep: 'authorization/addStep',
      saveSteps: 'authorization/saveSteps',
      resetState: 'authorization/resetState'
    }),
    getCenter () {
      if (this.authorization != null) {
        findOneCity(this.authorization.city_start)
          .then(r => {
            this.centerValue = [[r.latitude, r.longitude], [r.latitude, r.longitude]]
          })
          .catch(e => {
          // Return GPS coordinates to see the France
            this.centerValue = [[51.115187, -5.344928], [40.957331, 9.975715]]
          })
      }
    },
    onMapReady (map) {
      this.map = map
    },
    onMapUpdateCenter (center) {
      this.mapCenter = center
    },
    onMapUpdateBounds (bounds) {
      this.mapBounds = bounds
    },
    addStepToStore (step) {
      this.addStep({
        step,
        index: 0
      })
      this.map.panTo([step.properties.latitude, step.properties.longitude])
    },
    save (step) {
      this.saveSteps().then(() => {
        this.$router.push({
          name: 'authorizations.steps.create-next',
          params: {
            id: this.$route.params.id,
            num_step: step.properties.num_step
          }
        })
      })
    }
  },
  mounted () {
    this
      .getAuthorization(this.$route.params.id)
      .then(() => {
        this.getSteps(this.$route.params.id)
          .then(() => {
            if (this.steps.length) {
              // there is already some steps in database for this
              // authorization, we do not want to stay on this page, we
              // redirect the user to the last create-next page
              this.$router.push({
                name: 'authorizations.steps.create-next',
                params: {
                  id: this.$route.params.id,
                  num_step: this.steps[this.steps.length - 1].properties.num_step
                }
              })
            }
          })
      })
      .catch(() => this.$router.push({ name: '404' }))
  },
  beforeDestroy () {
    this.resetState()
  }
}
</script>

<style scoped lang="scss">
  .authorization-left-content {
    margin-top: 40px;
  }

  p {
    @media (max-width: $breakpoint-md) {
      font-size: 13px;
      margin-bottom: 35px !important;
    }
  }
</style>

<template>
  <div class="location-autocomplete">
    <h4 class="mb-3">{{ headingText }}</h4>
    <input v-model="text"
           type="text"
           placeholder="Rechercher par mot clé">
    <div v-if="options.length"
         class="features">
      <h4 class="mb-3">Sélectionnez dans les propositions suivantes</h4>
      <div v-for="(feature, index) in options"
           :key="index"
           @click="selectFeature(feature)"
           class="feature">
        {{ feature.properties.label }}
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'debounce'
import { autocomplete } from '@/api/openrouteservice'
import { featureToStepGeoJson } from '@/utils'

export default {
  props: {
    authorization: {
      type: Object,
      required: true
    },
    headingText: {
      type: String,
      required: false,
      default: 'Renseignez le premier point d\'intersection'
    },
    way: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      text: null,
      options: [],
      selected: null
    }
  },
  methods: {
    autocomplete (text) {
      if (!text) return
      autocomplete(text)
        .then(response => {
          this.options = response.data.features
        })
        .catch(error => alert(error))
    },
    selectFeature (feature) {
      this.selected = feature
      this.options = []
      this.text = feature.properties.label
      this.$emit('input', featureToStepGeoJson(
        feature,
        this.authorization,
        true, // draggable
        this.way
      ))
    }
  },
  watch: {
    text (value) {
      if (this.selected && value === this.selected.properties.label) return
      this.debounceAutocomplete(value)
    }
  },
  created () {
    this.debounceAutocomplete = debounce(this.autocomplete, 300)
  }
}
</script>

<style scoped lang="scss">
  .location-autocomplete {
    margin-bottom: 30px;
    position: relative;

    @media (max-width: $breakpoint-md) {
      margin-bottom: 25px;
    }

    input {
      width: 100%;
      border: 1px solid $red;
      border-radius: 3px;
      padding: 15px;

      @media (max-width: $breakpoint-md) {
        font-size: 14px;
        padding: 7px;
      }
    }
  }

  .features {
    margin: 35px 0;
    width: 100%;
    background: $white;

    @media (max-width: $breakpoint-md) {
      margin: 20px 0 28px 0;
    }
  }

  .feature {
    font-size: 14px;
    box-sizing: border-box;
    padding: 10px 0;
    border-bottom: 1px solid $gray-text-light;
    color: $gray-text-light;

    @media (max-width: $breakpoint-md) {
      font-size: 12px;
      padding: 7px 0;
    }

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      color: $gray-text;
      cursor: pointer;
    }
  }
</style>

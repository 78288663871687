<template>
  <button @click="click"
          class="btn btn-secondary btn-block">
    <template v-if="!isReturnButton">
      <i class="icon-location"></i> Ou ajouter un point sur la carte
    </template>
    <template v-else>
      <img src="../assets/images/icon-return.svg" alt=""> Ou ajouter un premier point de trajet retour
    </template>
  </button>
</template>

<script>
import { addressFromLatLng } from '@/api/openrouteservice'
import { featureToStepGeoJson } from '@/utils'

export default {
  props: {
    authorization: {
      type: Object,
      required: true
    },
    mapCenter: {
      type: Object,
      default: () => {}
    },
    way: {
      type: Number,
      required: true
    },
    isReturnButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    click () {
      addressFromLatLng(this.mapCenter.lat, this.mapCenter.lng)
        .then(response => {
          let feature = null
          if (response.data.features.length === 0) {
            feature = { properties: { label: null }, geometry: { coordinates: [this.mapCenter.lat, this.mapCenter.lng], type: 'Point' } }
          } else {
            feature = response.data.features[0]
          }
          const step = featureToStepGeoJson(
            feature,
            this.authorization,
            true, // draggable
            this.way
          )
          // to force the usage of ORS feature coordinates, comment the
          // following 3 lines.
          step.geometry.coordinates = [this.mapCenter.lng, this.mapCenter.lat]
          step.properties.latitude = this.mapCenter.lat
          step.properties.longitude = this.mapCenter.lng
          // step.properties.num_step = this.step.properties.num_step
          // step.properties.page_number = this.step.properties.page_number
          // step.properties.comment = this.step.properties.comment
          this.$emit('input', step)
        })
        .catch(error => alert(error))
    }
  }
}
</script>

<style scoped lang="scss">
  img {
    width: 18px;
    height: auto;
    vertical-align: -3px;
  }
</style>
